import './Article.css'
import ArticleItem from '../../components/articleItem/ArticleItem'

function Article(){
    return(
        <>


        </>
    )
}

export default Article