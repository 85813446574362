import Navbar from '../../components/navbar/Navbar'
import Course from '../../components/course/Course'
import Feature from '../../components/Feature/Feature'
import SliderRes from '../../components/sliderRes/SliderRes'
import Main from '../../components/main/Main'
import Accordion from '../../components/Accordion/Accordion'
import Text from '../../components/Text/Text'
import Card from '../../components/card/Card'
import Intro from '../../components/intro/Intro'
import Footer from '../../components/footer/Footer'

import img16 from '../../assets/images/16.png'
import img17 from '../../assets/images/17.png'
import img18 from '../../assets/images/18.png'
function Bus(){
    return(
        <>
        <Navbar></Navbar>
        <div className=" w-full  mb-5 sm:h-56 sm:w-4/5 sm:m-auto">
            <img className="z-0 rounded-3xl w-full " src="https://cdn.alibaba.ir/h2/desktop/assets/images/hero/hero-824e4df4.webp"/>
        </div>
        <Course></Course>
        <Feature></Feature>
        <div className="w-full h-auto  mt-5 mb-5 flex flex-row justify-center 
        sm:w-full
        md:w-full
        md:flex
        md:flex-row
        md:justify-center
">
            <img className="w-auto h-[180px] rounded-lg
            sm:w-3/5
            " src="https://cdn.alibaba.ir/h2/desktop/assets/images/breaking-news/phone-sales-4af98b55.webp"/>
        </div>
        <SliderRes></SliderRes>
        <Main></Main>
        <Accordion></Accordion>
        <Text
        title="بلیط اتوبوس"
        description="سفر با اتوبوس به دلیل پایین آوردن هزینه‌ها و البته راحتی و امنیت بالا مورد استقبال مسافران است. با توسعه فناوری‌های نوین و ایجاد رفتار خرید اینترنتی، دیگر نیازی نیست برای خرید بلیط اتوبوس به ترمینال شرق و سایر ترمینال‌های اتوبوس‌رانی مراجعه کنید.
علی‌بابا با توجه به اعتبار و پیشینه‌ای که در زمینه عرضه بلیط سفر و سایر خدمات گردشگری دارد، بهترین وب‌سایت رزرو بلیط اتوبوس است. شما می‌توانید در علی‌بابا بهترین قیمت را برای خرید بلیط اتوبوس پیدا کنید؛ همچنین این امکان را دارید تا از بین شرکت‎‌های اتوبوس‌رانی مختلف دست به انتخاب بزنید.
علی‌بابا به عنوان مرجع فروش بلیط اینترنتی اتوبوس، تمام مسیرهای زمینی داخل کشور و تعدادی از مسیرهای خارجی را پوشش می‌دهد.
در دسترس بودن لیست کاملی از شرکت‌های اتوبوس‌رانی در سایت علی‌بابا امکان خرید آنلاین بلیط اتوبوس را با قیمت مناسب برای شما ممکن می‌سازد.
از طرف دیگر اگر ترجیح شما رفاه و آرامش بیشتر در طول سفر است، می‌توانید بلیط اتوبوسی را بخرید که اگرچه قیمتش کمی بیشتر است، اما از امکانات رفاهی بیشتری نیز برخوردار است و سفر را برای شما دلپذیرتر می‌کند.
ضمنا شما هنگام خرید آنلاین بلیط اتوبوس از علی‌بابا، علاوه بر انتخاب صندلی، می‌توانید طول مسیر، ساعت حرکت و قوانین استرداد بلیط را نیز مشاهده کنید."
        ></Text>

<div className='w-full flex flex-col items-center
        md:w-3/5 md:flex md:flex-row md:justify-center md:m-auto
        '>
            <Card
            img={img16}
            Text="خرید اینترنتی بلیط اتوبوس، مسافران را از اینکه خیلی زودتر در ترمینال حضور داشته باشند، بی‌نیاز ساخته است. شما می‌توانید همین طور که در خانه نشسته‌اید، خرید بلیط برای اتوبوس را انجام دهید.
برای اینکه سفر را برای خود راحت‌تر و لذت‌بخش‌تر کنید، می‌توانید برای خرید بلیط VIP اقدام کنید. اگرچه قیمت بلیط اتوبوس VIP کمی گران‌تر است، اما ارزشش را دارد. ضمنا با خرید بلیط رویال سفر هم سفری بی‌دردسر را تجربه خواهید کرد، چرا که این شرکت هم شرایط رفاهی قابل‌قبولی برای مسافران فراهم می‌کند."
            ></Card>
            <Card
            img={img17}
            Text="خرید اینترنتی بلیط اتوبوس، مسافران را از اینکه خیلی زودتر در ترمینال حضور داشته باشند، بی‌نیاز ساخته است. شما می‌توانید همین طور که در خانه نشسته‌اید، خرید بلیط برای اتوبوس را انجام دهید.
برای اینکه سفر را برای خود راحت‌تر و لذت‌بخش‌تر کنید، می‌توانید برای خرید بلیط VIP اقدام کنید. اگرچه قیمت بلیط اتوبوس VIP کمی گران‌تر است، اما ارزشش را دارد. ضمنا با خرید بلیط رویال سفر هم سفری بی‌دردسر را تجربه خواهید کرد، چرا که این شرکت هم شرایط رفاهی قابل‌قبولی برای مسافران فراهم می‌کند."
            ></Card>
                        <Card
            img={img18}
            Text="خرید اینترنتی بلیط اتوبوس، مسافران را از اینکه خیلی زودتر در ترمینال حضور داشته باشند، بی‌نیاز ساخته است. شما می‌توانید همین طور که در خانه نشسته‌اید، خرید بلیط برای اتوبوس را انجام دهید.
برای اینکه سفر را برای خود راحت‌تر و لذت‌بخش‌تر کنید، می‌توانید برای خرید بلیط VIP اقدام کنید. اگرچه قیمت بلیط اتوبوس VIP کمی گران‌تر است، اما ارزشش را دارد. ضمنا با خرید بلیط رویال سفر هم سفری بی‌دردسر را تجربه خواهید کرد، چرا که این شرکت هم شرایط رفاهی قابل‌قبولی برای مسافران فراهم می‌کند."
            ></Card>
        </div>

        <Text
        title="استرداد بلیط اتوبوس"
        description="طبیعی است که گاهی اوقات، سفرتان به هر دلیلی لغو شود. در این زمان به دنبال استرداد بلیط (کنسلی) هستید. اگر بلیط‌تان را از علی‌بابا خریده‌اید، نگران نباشید؛ فرآیند استرداد بلیط اتوبوس در علی‌بابا راحت و بی‌دردسر است. کافی است وارد حساب کاربری‌تان شوید و روی نام کاربری‌تان کلیک کنید. در لیست نمایش داده شده، روی سفارشات و استردادها کلیک کنید.
در صفحه بعد شماره سفارش خود را وارد و سپس روی جستجو کلیک کنید. پس از اتمام مراحل استرداد، پولی که بابت خرید بلیط پرداخته‌اید، در کوتاه‌ترین زمان ممکن به حساب شما برمی‌گردد.
زمانی که بلیط را می‌خرید به قوانین بلیط هم دقت کنید. قوانین استرداد را در همان پنجره بلیط، در بخش «اطلاعات بیشتر» مشاهده خواهید کرد. این قوانین به شما می‌گوید که جریمه استرداد یا کنسلی بلیط اتوبوس چه میزان است"
        ></Text>

<Text
        title='استعلام بلیط اتوبوس'
        description='گاهی وقت‌ها بعد از اینکه به‌صورت اینترنتی بلیط اتوبوس خودتان را می‌خرید، نیاز دارید تا دوباره به آن بلیط اتوبوس سر بزنید و فرایند رهیری بلیط اتوبوس را انجام دهید. گاهی پیش می‌آید که باید بعضی از اطلاعات مهم و کاربردی بلیط خودتان را مجددا بررسی کنید تا روز حرکت اتوبوس به مشکل خاصی برنخورید و بدون دردسر، سفر خودتان را شروع کنید. مثلا ممکن است تاریخ حرکت اتوبوس را فراموش کرده باشید یا بخواهید شماره تعاونی و ترمینال را دوباره ببینید یا به همسفرتان خبر دهید. در چنین شرایطی لازم است که اصطلاحا استعلام بلیط اتوبوس یا رهگیری بلیط اتوبوس انجام دهید تا همه اطلاعات سفرتان کف دستتان باشد. هنگام خرید بلیط اتوبوس از علی بابا، اطلاعات بلیط و شماره سفارش برای شما پیامک و ایمیل می‌شود؛ اما اگر این پیامک و ایمیل را گم کردید، هیچ نگران نباشید. در ادامه توضیح می‌دهیم که چطور می‌توانید بلیط اتوبوستان را مجددا استعلام کنید. استعلام بلیط اتوبوس در وب‌سایت علی‌بابا بسیار ساده و سرراست است. فقط کافی است مراحل زیر را گام‌به‌گام انجام دهید '
        ></Text>

        <Text
        title='شرکت‌های اتوبوس‌رانی'
        description='در حال حاضر، شرکت‌های اتوبوس‌رانی متعددی وجود دارند که تمام مسیرهای داخل و برخی از مسیرهای خارج از کشور را پوشش می‌دهند. این شرکت‌ها در بسیاری از امکانات و خدمات مشترک‌اند و در عین حال تفاوت‌هایی با هم دارند.
زمانی که برای خرید بلیط اتوبوس جستجو می‌کنید، این شرکت‌ها گزینه‌های مختلفی برای شما دارند و می‌توانید با توجه به شرایط خود از بین آنها انتخاب کنید. در ادامه چند مورد از معروف‌ترین شرکت‌های اتوبوس‌رانی کشور را به شما معرفی می‌کنیم.'
        ></Text>

<Text
        title='رویال سفر ایرانیان'
        description='شرکت رویال سفر ایرانیان از سال 1385 کار خود را شروع کرد. می‌توان گفت با آغاز به کار رویال سفر بود که سطح سفرهای بین‌شهری با اتوبوس وارد مرحله جدیدی شد. اتوبوس‌های این شرکت با برخورداری از امکانات مدرن و به‌روز مسافران را با شکل جدیدی از سفر مواجه کرد. در حال حاضر، رویال سفر ایرانیان دارای 550 دستگاه اتوبوس VIP است. گفتنی است این شرکت در 50 شهر در داخل و خارج از کشور نمایندگی دارد. شما می‌توانید به سادگی بلیط اتوبوس رویال سفر و سایر شرکت‌ها را از علی‌بابا رزرو کنید.'
        ></Text>


        <Text
        title='سیر و سفر'
        description='یکی دیگر از شرکت‌های اتوبوس‌رانی معروف کشور، سیر و سفر است. این شرکت اتوبوس‌رانی در سال 1369 آغاز به کار کرد و از آن سال تا به حال فعالیت‌های خود را توسعه داده است. بسیاری از اتوبوس‌های شرکت سیر و سفر از نوع VIP هستند و امکانات رفاهی مطلوبی برای مسافران به وجود می‌آورند. شما می‌توانید به سادگی بلیط اتوبوس سیر و سفر و سایر شرکت‌ها را از علی‌بابا رزرو کنید.'
        ></Text>

        <Text
        title='همسفر'
        description='شرکت اتوبوس‌رانی همسفر از سال 1378 کار خود را شروع کرد. در ابتدا تنها هفت دستگاه اتوبوس برای این شرکت کار می‌کردند؛ اما با گذشت زمان و استقبال مردم از خدمات همسفر، تعداد اتوبوس‌‌ها و گستره فعالیت‌های آن افزایش یافت. شما برای سفر به نقاط مختلف کشور می‌توانید بلیط همسفر را هم آنلاین بخرید و راهی شوید. شما می‌توانید به سادگی بلیط اتوبوس همسفر و سایر شرکت‌ها را از علی‌بابا رزرو کنید.'
        ></Text>

        <Intro></Intro>
        <Footer></Footer>
        </>
    )
}

export default Bus